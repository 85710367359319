import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

export const PhoneInput = ({
  value,
  className,
  placeholder,
  mask = '+9 999 9999 9999 9999',
  secondaryMask = '999 9999 9999 9999',
  maskChar = null,
  alwaysShowMask = false,
  onChange,
}: any) => {
  const [phoneMask, setPhoneMask] = useState(mask);
  const [phoneValue, setPhoneValue] = useState('');
  
  const handlePhoneChange = (event: any) => {
    const newValue = event.target.value;

    if (!newValue) {
        setPhoneMask(mask);
        setPhoneValue('');
    } else {
        setPhoneValue(newValue);
    }
  };
  
  const handleBlur = (event: any) => {
    const newValue = event.target.value;

    const isStartsWith = newValue.startsWith('+');
    
    if (onChange) {
      if (isStartsWith) {
          onChange(newValue.trim());
      } else {
          if (newValue.trim()) {
            onChange(`+1 ${newValue.trim()}`);
          } else {
            onChange('');
          }
        }
    }
  };
  
  useEffect(() => {
    if (value) {
        const isStartsWith = value.startsWith('+1');
        if (isStartsWith) {
            setPhoneValue(value.slice(2).trim());
            setPhoneMask(secondaryMask);
        } else {
            setPhoneValue(value);
            setPhoneMask(mask);
        }
    }
  }, [value]);
  
  return (
    <InputMask
        className={className}
        placeholder={placeholder}
        mask={phoneMask}
        maskChar={maskChar}
        alwaysShowMask={alwaysShowMask}
        value={phoneValue}
        onChange={handlePhoneChange}
        onBlur={handleBlur}
    />
  )
};